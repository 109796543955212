import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Switch from '@mui/material/Switch';

const CONFIRM_ACTION_LABELS = [
  'I confirm I want to switch the reward method.',
  'I confirm these are the caps for Boost Redemptions.',
  'I confirm I want to switch the alerts method.',
  'I confirm I want to switch the purchasing method.']

function TabPanel(props) {
  const { children, tabView, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabView !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {tabView === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  tabView: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function BoostCAP({onFieldChange, boostCap}) {
  const [ val, setVal ] = useState(boostCap)

  const onChangeNumberField = (newValue) => {
    let value = newValue ? Number(newValue.target.value) : 0
    if (isNaN(value) || value < 0 || value === 0) {
      value = 1
    }
    if (value > 30) {
      value = 30
    }
    setVal(value)
    onFieldChange({ cap: value })

  }


  return (
    <Stack spacing={4} >
      <Typography sx={{weight: 700, fontSize: '16px', lineHeight: '24px'}}>
        Define how many Boost Redemptions a player can get in a given timeframe.
      </Typography>
      <Box sx={{ background: "#e3f2fd",
        height: '90px',
        display: 'flex',
        alignItems: 'start',
        }}>
        <Box sx={{marginTop: '19px'}}>
        <ErrorOutlineIcon sx={{margin: '8px', color: "#0288D1"}}/>
        </Box>
        <Box>
          <Typography sx={{fontSize: '14px', fontWeight: 400, margin: 2, display: 'flex'
            }} >
              <Box sx={{height: '10px', display: 'flex', marginTop: '12px'}}>

                Once the cap is reached the redemption will be placed for human review.
              </Box>
           </Typography>
        </Box>
      </Box>
      <Box>
      <FormControl variant="standard" sx={{ m: 1, mt: 3, width: '25ch' }}>
         <Input
           id="standard-adornment-cap"
           endAdornment={<InputAdornment position="end">cards</InputAdornment>}
           aria-describedby="standard-weight-helper-text"
           inputProps={{
             'aria-label': 'cap',
           }}
           value={val}
           onChange={onChangeNumberField}
         />
         <FormHelperText id="standard-weight-helper-text">Earned in the last 30 days</FormHelperText>
       </FormControl>
      </Box>
    </Stack>
  )

}

function BoostPurchasing({ onFieldChange, purchasingMethod }) {
  const [ newPurchasingMethod, setNewPurchasingMethod ] = useState(purchasingMethod)

  const onChangeMethod = (event) => {
    setNewPurchasingMethod(event.target.value)
    onFieldChange({ purchasing: event.target.value })
  }


  return (
    <Stack spacing={4} >
      <Typography sx={{weight: 700, fontSize: '16px', lineHeight: '24px'}}>
        Define how the Boost Redemptions purchase job will be triggered
      </Typography>
      <Box sx={{
                height: '90px',
                display: 'flex',
                alignItems: 'start',
                }}>
        <RadioGroup
            value={newPurchasingMethod}
            aria-label="giftcardsradiolabel"
            name="giftcardsradio"
            onChange={onChangeMethod}
          >
              <FormControlLabel
                value={'manual'}
                key={'manual'}
                control={<Radio />}
                label={<Stack direction={'row'} spacing={1} sx={{fontSize: '14px'}}><Typography component="span" sx={{fontWeight: 700, display: 'flex'}}>Manual</Typography><Typography sx={{marginLeft: 0}}></Typography> - The user manually triggers the purchase job.</Stack>}
              />
              <FormControlLabel
                value={'automatic'}
                key={'automatic'}
                control={<Radio />}
                label={<Stack direction={'row'} spacing={1} sx={{fontSize: '14px'}}><Typography component="span" sx={{fontWeight: 700, display: 'flex'}}>Automatic</Typography><Typography sx={{marginLeft: 0}}></Typography> - The purchase job triggers automatically.</Stack>}
              />
          </RadioGroup>
      </Box>
      <Box>

      </Box>
    </Stack>
  )

}

function CardOrPoints({ onFieldChange, boostRewardMethod }) {
  const [ rewardPointsUpdate, setRewardPointsUpdate ] = useState(boostRewardMethod && boostRewardMethod === 'points')

  const onChangeMethod = (event) => {
    console.log('CURRENT ', boostRewardMethod, rewardPointsUpdate)
    setRewardPointsUpdate(event.target.checked)
    onFieldChange({ reward: event.target.checked ? 'points' : 'card' })
  }
  const label = { inputProps: { 'aria-label': 'switch points' } };


  return (
    <Stack spacing={2} >
      <Typography sx={{weight: 400, fontSize: '16px', lineHeight: '10px'}}>
        By activating this gift cards from our inventory will no longer be
      </Typography>
        <Typography sx={{weight: 400, fontSize: '16px', lineHeight: '10px'}}>
            assigned directly to players; instead, they will receive the
        </Typography>
        <Typography sx={{weight: 400, fontSize: '16px', lineHeight: '10px'}}>
         equivalent amount in points.
      </Typography>
      <Box sx={{

                display: 'flex',
                alignItems: 'start',
                }}>
        <FormControlLabel
           control={
             <Switch {...label} checked={rewardPointsUpdate} color="error" onChange={onChangeMethod} />
           }
           label="Grant points instead of gift cards"
         />

      </Box>
      <Box>

      </Box>
    </Stack>
  )

}

export default function BoostRedmptionSettingActionContent({ onFieldChange, boostCap, handleChangeTabProp, purchasingMethod, boostRewardMethod }) {

  const [ tabView, setTabView ] = useState(1)
  const [ resetCheck, setResetCehck ] = useState(true)


  const handleChangeTab = (event, newValue) => {
    setTabView(newValue);
    setResetCehck(true)
    handleChangeTabProp()
  };

  const onCheckConfirm = (event) => {
    setResetCehck(!resetCheck)
    onFieldChange({ confirm: event.target.checked })
  }

  return (
    <Box sx={{ }}>
      <Box sx={{ width: '100%' }}>
       <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
         <Tabs value={tabView} onChange={handleChangeTab} aria-label="boost-red-dialog">
           <Tab label="CARDS OR POINTS" {...a11yProps(0)} />
           <Tab label="CAP" {...a11yProps(1)} />
           <Tab label="ALERTS" {...a11yProps(2)} />
           <Tab label="PURCHASING" {...a11yProps(3)} />

         </Tabs>
       </Box>
       <TabPanel tabView={tabView} index={0}>
         <Box sx={{margin: 1
         }}>
         <CardOrPoints onFieldChange={onFieldChange} boostRewardMethod={boostRewardMethod}/>
        </Box>
       </TabPanel>

       <TabPanel tabView={tabView} index={1}>
         <BoostCAP onFieldChange={onFieldChange} boostCap={boostCap}/>
       </TabPanel>

       <TabPanel tabView={tabView} index={2}>
         TBD
       </TabPanel>
       <TabPanel tabView={tabView} index={3}>
         <BoostPurchasing onFieldChange={onFieldChange} purchasingMethod={purchasingMethod}/>
       </TabPanel>

     </Box>
     <FormControlLabel control={<Checkbox checked={!resetCheck}/>} label={CONFIRM_ACTION_LABELS[tabView]} onChange={onCheckConfirm}/>

    </Box>
  )

}
