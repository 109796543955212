import React, { useEffect, useState } from 'react';
import { useAsyncDebounce } from 'react-table'
import { useGridApiContext } from '@mui/x-data-grid';

import auFlag from '../../assets/AU.png';
import usFlag from '../../assets/US.png';
import caFlag from '../../assets/CA.png';
import gbFlag from '../../assets/GB.png';

import { getPrizes, purchaseBoost, purchaseEdit, editCard, newCard } from '../../models/Prize'

import GiftCard from './GiftCard'
import GiftCardOverview from './GiftCardOverview'
import PurchasesGraph from './PurchasesGraph'
import BoostRedmptionSettingActionContent from './BoostRedmptionSettingActionContent'
import { AddNewCard } from './DenominationEditingContent'

import ConfirmDialog from '../common/ConfirmDialog'
import IMLoader from '../common/IMLoader'
import IMAlerts from '../common/IMAlerts';
import IMOnlineBadge from '../common/IMOnlineBadge'
import { createStyles, makeStyles } from "@material-ui/core";
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddCardIcon from '@mui/icons-material/AddCard';
import Switch from '@mui/material/Switch';
import SyncIcon from '@mui/icons-material/Sync';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material-next/Chip';
import FormGroup from '@mui/material/FormGroup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Avatar from '@mui/material/Avatar';

import {
  useGridApiRef,
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ImageListItem from '@mui/material/ImageListItem';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { formatDate, formatTime } from '../common/Utils'


function EditToolbar(props) {

}


export default function GiftCards(props) {
  const [ edit, setEdit ] = useState(null);
  const [ rows, setRows ] = useState(null);
  const [ rowModesModel, setRowModesModel ] = useState({});
  const [ editedPrize, setEditedPrize ] = useState(null);
  const [ globalFilter, setGlobalFilter ] = useState('')
  const [ filteredCards, setFilteredCards ] = useState(null)
  const [ refresh, setRefresh ] = useState(false)
  const [ statusFiltered, setStatusFiltered ] = useState(0)
  const [ countryFiltered, setCountryFiltered ] = useState(0)
  const [ boostCap, setBoostCap ] = useState(0)
  const [ boostRewardMethod, setBoostRewardMethod ] = useState(null)
  const [ queuedRed, setQueuedRed ] = useState({})
  const [ typeFiltered, setTypeFiltered ] = useState(0)

  const [ dialogProps, setDialogProps ] = useState(null);
  const [ loading, setLoading ] = useState(false)
  const [ alert, setAlert ] = useState(null)
  const [ loadingMessage, setLoadingMessage ] = useState('Loading Denominations Cards')
  const [ pending, setPending ] = useState(props.pending)
  const [ addedCard, setAddedCard ] = useState({ card: null, amount: 0, total: 0, confirmed: false, standardInventory: false, boostInventory: true, currency: null  })
  const [ changeRow, setChangeRow ] = useState(null);

  const [ boostEditing, setBoostEditing ] = useState(null);

  const [ boostPurchases, setBoostPurchases ] = useState(null);
  const [ boostPurchasingState, setBoostPurchasingState ] = useState('');
  const [ standardPurchases, setStandardPurchases ] = useState(null);
  const [ standardPurchasingState, setStandardPurchasingState ] = useState('');
  const [ boostPurchasingMethod, setBoostPurchasingMethod ] = useState('');

  // const [ enableBoostEditingConfirm, setEnableBoostEditingConfirm ] = useState(false)
  const [ confirmDialog, setConfirmDialog ] = useState(false)
  const [ currentBoostPurchasingStatus, setCurrentBoostPurchasingStatus ] = useState({})

  const [ currentStandardStatus, setCurrentStandardStatus ] = useState({})

  const [ currentBoostStatus, setCurrentBoostStatus ] = useState({})
  const [ prizesExtendedData, setPrizesExtendedData ] = useState({})
  const [ tangoBalance, setTangoBalance ] = useState(0)
  const apiRef = useGridApiRef();
  const COUNTRY_MAP = {
    'US': { icon: usFlag,  currency: '$'},
    'CA': { icon: caFlag,  currency: '$'},
    'GB': { icon: gbFlag,  currency: '$'},
    'AU': { icon: auFlag,  currency: '$'},

  }
  const setupGiftCardsRows = (cards) => {

    setRows(cards)
    setFilteredCards(cards)


  }


  const setStateFromResponse = (response) => {
    if (response.currentBoostStatus) {
      setEdit(response.edit)
      setTangoBalance(response.tangoBalance)
      setCurrentBoostStatus(response.currentBoostStatus)
      setCurrentStandardStatus(response.currentStandardStatus)

      // setCurrentBoostPurchasingStatus(response.currentBoostStatus)
      setBoostCap(response.currentBoostStatus.cap)
      setBoostRewardMethod(response.currentBoostStatus.boostRewardMethod)
      setBoostPurchases(response.currentBoostStatus.purchases)
      setStandardPurchases(response.currentStandardStatus.purchases)

      // setBoostPurchasingState(response.currentBoostStatus.purchasing_state)
      setStandardPurchasingState(response.currentStandardStatus.purchasingState)

      setBoostPurchasingMethod(response.currentBoostStatus.purchasingMethod)
      // setStandardPurchasingMethod(response.currentStandardStatus.purchasing_method)

      setQueuedRed({boostQueuedRedemptions: response.currentBoostStatus.queuedRedemptions,
         standardQueuedRedemptions: response.currentStandardStatus.queuedRedemptions})

    }
    if (response.cards) {
      console.log('setStateFromResponse', response.cards)

      setupGiftCardsRows(response.cards)
    }


  }
  const setupTangoCards = async () => {
    setLoading(true)
    const response = await getPrizes({ type: 'TangoCard' });
    console.log('RESPONSE PRIZES !!!', response)
    if (response && !response.error && response.cards) {
      setStateFromResponse(response)
      props.setGlobalVals(response)
      setPrizesExtendedData(response.prizesExtendedData)
    } else {

    }
    setLoading(false)
  }



  const purchaseBoosts = async () => {
    setLoading(true)
    setLoadingMessage('Processing new Boosts purchases')
    const response = await purchaseBoost({admin: props.currentUser});
    if (response && !response.error && response.boostOrdered) {
      setStateFromResponse(response)
      props.setGlobalVals(response)
      setAlert({ display: true, severity: 'success', message: 'Ordering Process completed ' })
      const content = <Box
        sx={{minWidth: '1800px'}}
      >
      <Stack spacing={2}>
        <Typography key={`BOOSTORDEREDKEY`} sx={{fontWeight: 900}}>
         BOOST ORDERED:
         </Typography>
          {response.boostOrdered.length === 0
            ? 'No new orders'
            : response.boostOrdered.map((card, i) =>
              <Stack direction="row" spacing={2}
                 sx={{ alignItems: 'center'}}>
                   <Typography key={`${card['sku']}${i}key`}>
                    { `${card['sku']}: ${card['boostOrdered']} cards` }
                    </Typography>
                </Stack>
            )}
          <Typography key={`BOOSTFROMSTOCKKEY`} sx={{fontWeight: 900}}>
           BOOST FROM STOCK:
           </Typography>
            {response.boostFromStock.length === 0
              ? 'None from stock'
              : response.boostFromStock.map((card, i) =>
                <Stack direction="row" spacing={2}
                   sx={{ alignItems: 'center'}}>
                     <Typography key={`${card['sku']}${i}key`}>
                      { `${card['sku']}: ${card['fromStock']} cards` }
                      </Typography>
                  </Stack>
              )}
            <Typography key={`BOOSTERRORKKEY`} sx={{fontWeight: 900}}>
             BOOST ERRORS:
             </Typography>
              {response.boostOrderedErrors.length === 0
                ? 'No Errors Purchasing'
                : response.boostOrderedErrors.map((card, i) =>
                  <Stack direction="row" spacing={2}
                     sx={{ alignItems: 'center'}}>
                       <Typography key={`${card['sku']}${i}key`}>
                        { card['sku'] }
                        </Typography>
                    </Stack>
                )}
       </Stack>
       </Box>

      const dialogProps = {
        content,
        title: 'Process Purchase',
        type: 'Order',
        hideCancel: true
      }

      setDialogProps(dialogProps)
    } else {
      setDialogProps(null)
      setAlert({ display: true, severity: 'error', message: response.message || response.error  })
    //
    }
    setLoading(false)
    setLoadingMessage(null)

  }

  const onEditingPurchase = async ({type, action}) => {
    let loadingMessageUpdated = `${action} ${type} Request to Server`
    setLoading(true)
    setLoadingMessage(loadingMessageUpdated)
    const response = await purchaseEdit( {type, action});
    if (response && !response.error ) {
      setStateFromResponse(response)
      props.setGlobalVals(response)
      setAlert({ display: true, severity: 'success', message: response.message || 'Ordering Process completed ' })
      // if (action === 'STOP') {
        // loadingMessageUpdated = 'Refecthing Updated data'
        setLoadingMessage('Refecthing Updated data')
        // purchasing hard stopped , might need to refresh the page info...
        setupTangoCards()
        return


    } else {
      // setDialsogProps(null)
      setAlert({ display: true, severity: 'error', message: response.message || response.error  })
    //
    }
    setLoading(false)
    setLoadingMessage(null)

  }

  useEffect(() => {
    if (!rows ) {

      if (props.globalVals) {

        setStateFromResponse(props.globalVals)
      } else {

        setupTangoCards()
      }
    }
  })


  function BoolCell(props) {
      const { value } = props;
      return (
        value === 'unsupported'
          ? <></>
          : value === true
          ? <IconButton aria-label="CheckIcon" >
             <CheckIcon  color={'primary'}/>
           </IconButton>
          : <IconButton aria-label="CloseIcon" >
             <CloseIcon  color={'error'}/>
           </IconButton>
      )
  }
  function NameCell(props) {
    const { row } = props
    if (props.value == null) {
      return null;
    }

    return <Stack key={row.image} direction="row" spacing={2}>
              <ImageListItem key={row.image} sx={{width: '40px'}}>
              < IMOnlineBadge imgSrc={row.image} title={'GC'}/>

              </ImageListItem>

              <Typography>{props.value}</Typography>

            </Stack>
  }
  function ActiveStatusCell(props) {

    if (props.value == null) {
      return null;
    }

    return (
      <Chip label={props.value} color={props.value === 'Unavailable' ? "error" : props.value ===  'Disabled' ? 'disabled' : 'success'} />
    );
  }
  function CountryCell(props) {
    let comp = <>{props.value}</>
    switch (props.value) {
      case 'US': comp =  <><img src={usFlag} alt="usFlag" style={{ height: '20px', width: '40px', paddingRight: '11px' }}/> US</>
      break;
      case 'CA': comp = <><img src={caFlag} alt="caFlag" style={{ height: '20px', width: '40px', paddingRight: '11px' }}/> CA</>
      break;
      case 'GB': comp = <><img src={gbFlag} alt="gbFlag" style={{ height: '20px', width: '40px', paddingRight: '11px' }}/> GB</>
      break;
      case 'AU': comp = <><img src={auFlag} alt="auFlag" style={{ height: '20px', width: '40px', paddingRight: '11px' }}/> AU</>
    }

    return comp
    }


  const onBackCall = () => {
    setEditedPrize(null)
  }

  const handleViewClick = (id) => () => {
    const row = rows.filter((r) => r.id === id)[0]

    if (!row || !row.prizeId ) {
      return;
    }
    const viewedPrizeCards = rows.filter((r) => r.prizeId === row.prizeId)
    setEditedPrize(viewedPrizeCards)
  };
  function InStockCell(props) {
    const { id, value, field, row, setChangeRow } = props

    return (<Box sx={{
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: row.inStockTarget > value ? '#FEEBEE' : 'transparent'

    }}><Typography>{props.value}</Typography></Box>)
  }

  const columns = [
    { field: 'brandAndProviderStatus', align: 'left',headerName: 'Brand and Provider’s Status', width: 550, editable: false,
      renderCell: (params) => <NameCell {...params} />},
    { field: 'country', align: 'left',headerName: 'Country', width: 100, editable: false, renderCell: (params) => <CountryCell {...params} /> },
    { field: 'internalStatus', align: 'left',headerName: 'Internal Status', width: 120, editable: false,

      renderCell: (params) => <ActiveStatusCell {...params} /> },
    { field: 'standard',
            align: 'left',
            headerName: 'Standard',
            width: 100,
            editable: false,
            renderCell: (params) => <BoolCell {...params} />,
             },
    { field: 'boost',
              align: 'left',
              headerName: 'Boost',
              width: 100,
              editable: false,

              renderCell: (params) => <BoolCell {...params} />,
                },
    { field: 'inStockTarget', align: 'center',headerName: 'In-Stock Target', width: 150, editable: false },
    { field: 'boostStockLevel', align: 'center',headerName: 'In-Stock', width: 150, editable: false, renderCell: (params) => <InStockCell {...params} /> },

    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {

        return [
          <GridActionsCellItem
           icon={<VisibilityIcon />}
           label="View"
           disabled={edit === false}
           sx={{
             color: 'primary.main',
           }}
           onClick={handleViewClick(id)}
         />

        ];
      },
    },

  ];

  const filterByDescription = (rowsToFilter, value) => {
    if (value) {
      rowsToFilter = rowsToFilter.filter((card) => card.name.toLowerCase().includes(value.toLowerCase()));
    }
    return rowsToFilter
  }
  const filterByType = (rowsToFilter, nextType) => {
    if (!nextType) {
      return rowsToFilter
    }
    rowsToFilter = nextType === 'all'
      ? rowsToFilter
      : rowsToFilter.filter((row) => row[nextType] )

    return rowsToFilter
  }
  const filterByStatus = (rowsToFilter, nextStatus) => {
    if (!nextStatus) {
      return rowsToFilter
    }

      rowsToFilter = nextStatus === 'all'
        ? rowsToFilter
        : rowsToFilter.filter((row) => row.internalStatus === nextStatus.toLowerCase() )

      return rowsToFilter
  }
  const filterByCountry = (rowsToFilter, nextCountry) => {
    if (!nextCountry) {
      return rowsToFilter
    }

      rowsToFilter = nextCountry === 'all'
        ? rowsToFilter
        : rowsToFilter.filter((row) => row.country.toLowerCase() === nextCountry.toLowerCase() )

      return rowsToFilter
  }

  const handleChangeTypeFilter = (event) => {
    const filterTypeValue = event.target.value
    setTypeFiltered(filterTypeValue)

    let newFilteredCards = filterByDescription(rows, globalFilter)
    newFilteredCards = filterByStatus(newFilteredCards, statusFiltered)
    newFilteredCards = filterByCountry(newFilteredCards, countryFiltered)

    newFilteredCards = filterByType(newFilteredCards, filterTypeValue)

    setFilteredCards(newFilteredCards)
  }

  const handleChangeStatusFilter = (event) => {
    const filterStatusValue = event.target.value
    setStatusFiltered(filterStatusValue)


    let newFilteredCards = filterByDescription(rows, globalFilter)
    newFilteredCards = filterByType(newFilteredCards, typeFiltered)
    newFilteredCards = filterByCountry(newFilteredCards, countryFiltered)

    newFilteredCards = filterByStatus(newFilteredCards, filterStatusValue)

    setFilteredCards(newFilteredCards)

  }
  const handleChangeCountryFilter = (event) => {
    const filterCountryValue = event.target.value
    setCountryFiltered(filterCountryValue)

    let newFilteredCards = filterByDescription(rows, globalFilter)
    newFilteredCards = filterByType(newFilteredCards, typeFiltered)

    newFilteredCards = filterByStatus(newFilteredCards, statusFiltered)

    newFilteredCards = filterByCountry(newFilteredCards, filterCountryValue)


    setFilteredCards(newFilteredCards)

  }


  const globalFilterChange = (globalFilterValue) => {
    let newFilteredCards = filterByStatus(rows, statusFiltered)
    newFilteredCards = filterByType(newFilteredCards, typeFiltered)
    newFilteredCards = filterByCountry(newFilteredCards, countryFiltered)

    newFilteredCards = filterByDescription(newFilteredCards, globalFilter)

    setFilteredCards(newFilteredCards)
    setRefresh(!refresh)
  }

  const onChangeText = useAsyncDebounce(value => {
    setGlobalFilter(value.trim() === true ? '' : value || '')
    globalFilterChange(value)

  }, 200)

  const onCloseAlert = () => {
    setAlert(null)
  }

  const onCloseDialog = () => {
    setDialogProps(null)
    setBoostEditing(null)
    setAddedCard(null)
  }
  const onDialogCancel = (dialogEdited) => {
    setDialogProps(null)
    setAddedCard(null)
  }

  const addFunds = async () => {
    setLoadingMessage("Adding Funds... ")
    setLoading(true)

    const response = await editCard({action: 'fund' })
    console.log('RESPONSE FUND???? ', response)
    let message = ''
    if (response.status === 200) {
      message = "Account Funds Updated!"
      setStateFromResponse(response)

    } else {
      message = response.error || 'Failed to Update!'
    }
    const severity = response.status === 200 ? 'success' : 'error'

    setAlert({ display: true, severity, message })

    setLoading(false)
    setLoadingMessage(null)
  }
  const editBoostConfirmed =  async () => {
    if (!boostEditing) {
      return
    }
    setLoadingMessage(`Editing Boost ${Object.keys(boostEditing)[0]}`)
    setLoading(true)

    const response = await editCard({action: Object.keys(boostEditing)[0], type: 'boost', ...boostEditing })
    console.log('RESPONSE ???? ', response)
    let message = ''
    if (response.status === 200) {
      message = response.message || `${Object.keys(boostEditing)[0].toUpperCase()} Updated!`
      // switch (Object.keys(boostEditing)[0]) {
      //   case 'fund':
      //   case 'fund'
      //   case 'purchasing':
      //     setStateFromResponse(response)
      //     break;
      //   default:
      // }
      setStateFromResponse(response)
    } else {
      onCloseDialog()
      message = response.error || 'Failed to Update!'
    }
    const severity = response.status === 200 ? 'success' : 'error'

    setAlert({ display: true, severity, message })

    setLoading(false)
    setLoadingMessage(null)

  }
  const onConfirmDialog = (editedDialog) => {

    if (dialogProps && Object.keys(dialogProps).length > 0) {
      const dialogType = dialogProps.type
        switch (dialogType) {

        case 'Order':
          setDialogProps(null)
          setupTangoCards()
          setLoadingMessage('Re Loading cards')
          setAlert(null)
          break
          return;

        case 'EditBoost':
          editBoostConfirmed()
          break;
        case 'Add':
          addCardConfirmed()
          break;
        default:
          console.log("un supported dialog type");

      }
      onCloseDialog()
    }

  }

  const loaderSync = {

         animation: "spin 2s linear infinite",

        "@keyframes spin": {
          "0%": {
            transform: "rotate(360deg)"
          },
          "100%": {
            transform: "rotate(0deg)"
          }
        }
  }
  const newCardChange = (field) => (e) => {
    const value = e.target.value
    let newCardUpdate = addedCard
    newCardUpdate[field] = value
    setAddedCard(newCardUpdate)
  }


  const onEditCard = (editedCard) => {
    const editedRows = rows.map((row) => row.id === editedCard.id ? editedCard : row)
    setRows(editedRows)
    setFilteredCards(editedRows)
    // props.setCurrentCards(editedRows)
    setupTangoCards()
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const onBoostDialog = () => {

    const onFieldChange = (fieldsChanged) => {
      const action = Object.keys(fieldsChanged)[0]
      switch (action) {
        case 'confirm':
          // setEnableBoostEditingConfirm(fieldsChanged[action])
          setConfirmDialog(fieldsChanged[action])
          break;
        case 'cap':
        case 'reward':
        case 'purchasing':
          setBoostEditing(fieldsChanged)
        break;
      }
    }
    const handleChangeTab = () => {
      // setEnableBoostEditingConfirm(false)
      setConfirmDialog(false)
    }
    const content = <BoostRedmptionSettingActionContent
      purchasingMethod={boostPurchasingMethod}
      boostCap={boostCap}
      handleChangeTabProp={handleChangeTab}
      boostRewardMethod={boostRewardMethod}
      onFieldChange={onFieldChange}/>
    setDialogProps(
      { content,
        title: 'Boost Redemptions Settings',
        type: 'EditBoost',
        contentConfirm: '',
        enableConfirm: confirmDialog,
        confirmBtnColor: 'error',
        cancelBtnText: 'CLOSE',
        confirmBtnText: 'CONFIRM',
       })

  }
  const addCardConfirmed = async () => {
    // console.log('ADDING CARD CONFIRMED', addedCard)
    setLoadingMessage("Adding New Denomination")
    setLoading(true)

    const response = await newCard(addedCard)
    // console.log('RESPONSE ???? ', response)
    let message = ''
    if (response.status === 200) {
      message = "New Card Added"
      await setupTangoCards()
    } else {
      onCloseDialog()
      message = response.error || 'Failed to Add!'
    }
    const severity = response.status === 200 ? 'success' : 'error'

    setAlert({ display: true, severity, message })

    setLoading(false)
    setLoadingMessage(null)
  }
  const addNewCard = () => {

    const onNewCardFieldsChange = ({ card, amount, total, confirmed, standardInventory, boostInventory, currency }) => {
      // console.log('NEW ???? ', card, amount, total, confirmed, standardInventory, boostInventory, currency)
      setAddedCard({ card, amount, total, confirmed, standardInventory, boostInventory, currency })
    }

    const content = <AddNewCard
                      onNewCardFieldsChange={onNewCardFieldsChange}
                      cards={prizesExtendedData.prizesByCountry}
                      onCheckToggle={setConfirmDialog}
                    />
    setDialogProps(
      { content,
        title: 'Add a new Gift Card',
        type: 'Add',
        contentConfirm: '',
        confirmBtnColor: 'error',
        cancelBtnText: 'CLOSE',
        confirmBtnStartIcon: <CheckCircleIcon />,
        confirmBtnText: 'ADD CARD DENOMINATION',
        enableConfirm: confirmDialog
       })

  }
  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
    {loading && <IMLoader message={loadingMessage || 'Loading Denomination Cards...'} />}
    {alert && alert.display &&
      <IMAlerts
        onClose={onCloseAlert}
        severity={alert.severity}
        message={alert.message}/>}
    {dialogProps && <ConfirmDialog
        onCancel={onDialogCancel}
        contentConfirm={dialogProps.contentConfirm}
        {...dialogProps}
        onConfirm={onConfirmDialog}
        enableConfirm={confirmDialog}
        hideCancel={dialogProps.hideCancel}
        />}


      {rows && !editedPrize
        ? <>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={0}>
            <Grid item xs={8}>
              {(standardPurchases || boostPurchases) && <PurchasesGraph boostPurchases={boostPurchases} standardPurchases={standardPurchases} />}
            </Grid>
            <Grid item xs={4}>
            { loading
              ? <IMLoader message={'Updating Gift Cards Overview Details..'} mini/>
              : <GiftCardOverview
                  currentBoostStatus={currentBoostStatus}
                  currentStandardStatus={currentStandardStatus}
                  queuedRed={queuedRed}
                  onEditingPurchase={onEditingPurchase}
                  addFunds={addFunds}
                  onBoostDialog={onBoostDialog}
                  tangoBalance={tangoBalance}
                  />}

            </Grid>
          </Grid>


        </Box>
          <Box  sx={{backgroundColor: 'white', marginTop: 5
          }}>
            <Grid container spacing={0}>
             <Grid item xs={2}>
               <TextField
                  label="Search by description"
                  id="filled-start-adornment"
                  sx={{ m: 1, width: '25ch' }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                  }}
                  variant="outlined"
                  onChange={e => {
                    onChangeText(e.target.value);
                  }}
                />
             </Grid>

             <Grid item xs={2}>
               <FormControl sx={{ m: 1, minWidth: 180 }}>
                  <InputLabel id="selectCountryID">Country</InputLabel>
                  <Select
                    labelId="selectCountryStatus"
                    id="selectCountryId"
                    value={countryFiltered}
                    label="Country"
                    onChange={handleChangeCountryFilter}
                  >
                    <MenuItem value={'all'}>All</MenuItem>
                    <MenuItem value={'us'}>US</MenuItem>
                    <MenuItem value={'ca'}>CA</MenuItem>
                    <MenuItem value={'au'}>AU</MenuItem>
                    <MenuItem value={'uk'}>UK</MenuItem>

                  </Select>
                </FormControl>
             </Grid>
             <Grid item xs={2}>
               <FormControl sx={{ m: 1, minWidth: 180 }}>
                  <InputLabel id="selectScoreStatusIL">Status</InputLabel>
                  <Select
                    labelId="selectStatus"
                    id="selectStatusId"
                    value={statusFiltered}
                    label="Status"
                    onChange={handleChangeStatusFilter}
                  >
                    <MenuItem value={'all'}>All</MenuItem>
                    <MenuItem value={'Active'}>Active</MenuItem>
                    <MenuItem value={'Disabled'}>Disabled</MenuItem>
                    <MenuItem value={'Unavailable'}>Unavailable</MenuItem>
                    <MenuItem value={'Replaced'}>Replaced</MenuItem>

                  </Select>
                </FormControl>
             </Grid>
             <Grid item xs={2}>
               <FormControl sx={{ m: 1, minWidth: 180 }}>
                  <InputLabel id="selectScoreTypeIL">Type</InputLabel>
                  <Select
                    labelId="selectScoreType"
                    id="selectScoreTypeId"
                    value={typeFiltered}
                    label="Type"
                    onChange={handleChangeTypeFilter}
                  >
                    <MenuItem value={'all'}>All</MenuItem>
                    <MenuItem value={'standard'}>Standard</MenuItem>
                    <MenuItem value={'boost'}>Boost</MenuItem>
                  </Select>
                </FormControl>
             </Grid>
             <Grid item xs={2.5} sx={{display: 'flex',  alignItems: 'center !important', float: 'right'}}>
               <Button sx={{height: '40px'}} variant="contained" onClick={addNewCard} startIcon={<AddCardIcon/>}>
                 ADD NEW
               </Button>
             </Grid>

            </Grid>

          </Box>
          <DataGrid
              apiRef={apiRef}
              editMode="row"
              rows={filteredCards}
              columns={columns}
              sx={{backgroundColor: 'white', borderColor: 'transparent'
              }}
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}


              slots={{
                toolbar: EditToolbar,
              }}
              slotProps={{
                toolbar: { setRows, setRowModesModel, setFilteredCards },
              }}
              hideFooterRowCount={true}
              hideFooterSelectedRowCount
              initialState={{
                  sorting: {
                    sortModel: [{ field: 'inStockTarget', sort: 'desc' }],
                  },
                }}
            /></>
    : rows && editedPrize
            ?  <>
                <Stack direction="row" spacing={2}
                  sx={{width: '100%', alignItems: 'center', fontSize: 24, marginLeft: '15px'}}>
                  <Button
                    onClick={onBackCall}
                    sx={{ alignItems: 'center',
                      justifyContent: 'end',
                      backgroundColor: '#1976D24D',
                           position: 'inherit',
                          left: '70px', width: '58px', height: '59px', width: '59px',
                          borderRadius: '64px'}}
                         variant="contained" endIcon={<ArrowBackIcon color='primary' sx={{ fontSize: '40px !important' }} fontSize="large" />}
                  ></Button>

                    <Stack spacing={1}>
                      <Typography sx={{ marginLeft: 1, fontSize: '34px'}} color="text.primary" >
                        {editedPrize[0].name}
                      </Typography>
                      <Stack direction="row" spacing={1} >
                        <Chip
                          avatar={<Avatar alt={editedPrize[0].country} src={COUNTRY_MAP[editedPrize[0].country].icon} >{editedPrize[0].country}</Avatar>}
                          label={editedPrize[0].country}
                        />
                        <Chip label={'Provider: TangoCard'} />
                        <Chip label={`Prize ID: ${editedPrize[0].prizeId}`} />
                      </Stack>
                    </Stack>
                </Stack>
                <GiftCard
                 rows={editedPrize}
                 currentUser={props.currentUser}
                 editAll={true}
                 refresh={props.refreshToggle}
                 onEditCard={onEditCard}
               />
               </>
             : <></>
    }
    </Box>
  );
}
