import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import FormControlLabel from '@mui/material/FormControlLabel';
import {
  useGridApiRef,
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';

import { randomStatusOptions, randomPrice } from '@mui/x-data-grid-generator';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Chip from '@mui/material-next/Chip';

import AddCardIcon from '@mui/icons-material/AddCard';
import SettingsIcon from '@mui/icons-material/Settings';
// import ConfirmDialog from '../common/ConfirmDialog'
import { formatDate, formatTime } from '../common/Utils'


export default function GiftCardOverview(props) {
  const [ open, setOpen ] = useState(false);
  const [ standardQueued, setStandardQueued ] = useState({count: 0, amount: 0})
  const [ boostQueued, setBoostQueued ] = useState({count: 0, amount: 0})
  const [ rows, setRows ] = useState([])
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const clickMenuItem = (onClick) => (event) => {
    setOpen(false);
    onClick()
  }
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    if (props.queuedRed && props.queuedRed.standardQueuedRedemptions) {
      setStandardQueued(props.queuedRed.standardQueuedRedemptions)
      setBoostQueued(props.queuedRed.boostQueuedRedemptions)
      setupRows({boostQueuedRedemptions: props.queuedRed.boostQueuedRedemptions, standardQueuedRedemptions: props.queuedRed.standardQueuedRedemptions})
    }

    prevOpen.current = open;
  }, [open]);
  const apiRef = useGridApiRef();

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const usdPrice = {
    type: 'number',
    width: 130,
    valueFormatter: (value) => currencyFormatter.format(value.value),
    cellClassName: 'font-tabular-nums',
  };

  function WhatCell(props) {
    const capitalized  = props.value.charAt(0).toUpperCase() + props.value.slice(1)
    let comp = <>{capitalized}</>
    switch (props.value) {

      case 'boost': comp =  comp = <>{props.value}<AddCardIcon/>

      </>
      break;
      case 'standard': comp = <>{props.value}</>
      break;
    }

    return comp
    }
  const handleSettingsClick = (id) => () => {
    const row = rows.filter((r) => r.id === id)[0]
    props.onBoostDialog()
  };


  const handleViewClick = (id) => () => {
    const tangoRow = rows.filter((row) => row.id === id)[0]

    const type = id == 1 ? 'standard' : 'boost'
    const action = id == 1 ? props.currentStandardStatus.purchasingAvailableAction : props.currentBoostStatus.purchasingAvailableAction
    props.onEditingPurchase({type, action})

  }
  const getPurchasingState = (id) => {
    return id === 1
      ? { color: props.currentStandardStatus.purchasingState.includes('-') ? 'primary' : 'error', label: props.currentStandardStatus.purchasingAvailableAction }
      : {
        color: props.currentBoostStatus.purchasingState.includes('-') ? 'primary' : 'error',
        label: props.currentBoostStatus.purchasingAvailableAction}
  }
  const getTooltip = (id) => {
    return id === 2
      ? props.currentBoostStatus.nextPurchasingInfo
      : props.currentStandardStatus.nextPurchasingInfo

  }
  const columns = [
    { field: 'what', headerName: 'What', width: 100, editable: false, renderCell: (params) => <WhatCell {...params} />  },
    { field: 'queued', headerName: 'Queued', align: 'right',width: 70, editable: false, type: 'number' },
    { field: 'price', headerName: 'Value', ...usdPrice },
    {
      field: 'status', headerName: 'Status',editable: false
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {

        return [
          <Tooltip title={getTooltip(id)}>
            <GridActionsCellItem
             icon={

               <Button
                onClick={handleViewClick(id)}
                disabled={id === 2 && props.currentBoostStatus.disabled }
                variant="contained" color={getPurchasingState(id).color}>{getPurchasingState(id).label}</Button>}
                sx={{cursor: id === 2 && props.currentBoostStatus.disabled ? 'not-allowed' : 'pointer'}}

           />
         </Tooltip>
        ];
      },
    },

  ]


  const setupRows = ({ boostQueuedRedemptions, standardQueuedRedemptions }) => {
    boostQueuedRedemptions =  boostQueuedRedemptions && boostQueuedRedemptions.count ? boostQueuedRedemptions : boostQueued
    standardQueuedRedemptions =  standardQueuedRedemptions && standardQueuedRedemptions.count ? standardQueuedRedemptions : standardQueued

    setRows( [
      { id: 1, what: 'Standard', status: props.currentStandardStatus.purchasingState, queued: standardQueuedRedemptions.count, price: standardQueuedRedemptions.amount, purchasing: props.currentStandardStatus.purchasingState.toLowerCase() },
      { id: 2, what: 'BOOST', status: props.currentBoostStatus.purchasingState, queued: boostQueuedRedemptions.count, price: boostQueuedRedemptions.amount, purchasing: props.currentBoostStatus.purchasingState.toLowerCase() },

    ])
  }

  const card = (
    <React.Fragment>

      <CardContent >
        <Typography variant="h6" sx={{ fontSize: 20, textAlign: 'center', fontWeight: 500 }} color="text.secondary" gutterBottom>
          Gift Card Overview
        </Typography>
        <Typography variant="h5" component="div" sx={{ fontSize: 12, textAlign: 'center', color: '#9e9e9e', fontWeight: 500, marginBottom: 2 }}>
          {`Last Checked: ${formatDate(new Date())},${formatTime(new Date())}`}
        </Typography>
          <Stack direction='row' spacing={2}>
            <Chip
              onClick={props.onBoostDialog}
              icon={<SettingsIcon  />}
              label={"Boost Settings"}
             />
            <Chip
              icon={<AccountBalanceWalletIcon  />}
              onClick={props.addFunds}
              label={`$${Number.parseFloat(props.tangoBalance).toFixed(2)}`}          />
          </Stack>
        <DataGrid

          apiRef={apiRef}
          rows={rows}
          columns={columns}
          sx={{
            backgroundColor: 'white',
            borderColor: 'transparent',
            display: 'flex'
          }}
          disableColumnFilter
          hideFooterRowCount={true}
          hideFooterSelectedRowCount
          hideFooterPagination
        />
      </CardContent>
      <CardActions>
      </CardActions>
    </React.Fragment>
  );
  return (

    <Box sx={{ minWidth: 275}}>
      <Card variant="outlined"
      >{card}</Card>
    </Box>
  );
}
